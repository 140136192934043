.warningText {
  border: 2px solid #ff9900;
  padding: 3px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #ffefc2;
  padding-right: 10px;

  .warningIcon {
    color: #ff9900;
    font-size: 18px;
    margin: 0px 10px;
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  margin: 20px 0 0;
  justify-content: space-evenly;

  .inputField {
    border-radius: 4px !important;
    border: 1px solid #8b8b8b !important;
  }

  .label {
    width: 90px;
    text-align: end !important;
  }
}
.errorText {
  color: #ce0e2d;
}

.viewPenBtn {
  background-color: #ea9999 !important;
  border-color: #2b78e4 !important;
  color: #000000 !important;
  &:hover {
    background-color: #fac8c8 !important;
    color: #000000 !important;
  }
  &:focus-visible {
    color: #000 !important;
  }
}
