@mixin inputBtn() {
  border-radius: 4px !important;
  //border: 1px solid #8b8b8b !important;
}

// sass function to hide minor gridlines from heifer
@mixin hideGridLines() {
  .recharts-cartesian-grid-horizontal {
    line:not(:first-child):not(:nth-child(5n + 1)):not(:last-child) {
      stroke: none !important;
    }
  }
}

.ant-input {
  @include inputBtn();
}

.ant-input-number {
  @include inputBtn();
}

.ant-picker {
  @include inputBtn();
}

.ant-select {
  @include inputBtn();
}

.ant-btn {
  border-radius: 6px !important;
}

.primaryBtn {
  text-align: center !important;
  background: #17a697 !important;
  color: white !important;
  border-color: #2b78e4 !important;
}
.primaryBtn:hover {
  background: #17b7a5 !important;
  color: white !important;
}
.primaryBtn:active {
  background: #17b7a5 !important;
  color: white !important;
  border-color: #2b78e4 !important;
}
.primaryBtn:focus {
  background: #17a697 !important;
  color: white !important;
  border-color: #2b78e4 !important;
}
.primaryBtn:visited {
  background: #17a697 !important;
  color: white !important;
  border-color: #2b78e4 !important;
}
.primaryBtn:focus-within {
  background: #17a697 !important;
  color: white !important;
  border-color: #2b78e4 !important;
}

.requiredField:before {
  content: '*';
  color: red;
}

.requiredFieldAfter:after {
  content: '*';
  color: red;
}

.selectBoxContainer {
  .selectBoxLabel {
    font-size: 18px;
    color: #000000;
  }
  .selectBox {
    margin-left: 15px;
    border: 1px solid black;
    border-radius: 5px;
    .ant-select-selector {
      border-radius: 5px !important;
    }
  }
}

.ant-select-selector {
  border-color: white !important;
}

// ---------------------------------------------------------UsersScreen----------------------------------------------------------------

.tabContainer {
  .react-tabs__tab--selected {
    background: #fff !important;
    border-radius: 0px;
    border-bottom: 1px solid #e3e3e3 !important;
  }
  .react-tabs__tab {
    background: #d3d3d3;
    border-color: black;
    border-radius: 0px;
    border-bottom: 1px solid black;
  }
}

// ---------------------------------------------------ListOfAllUsers-----------------------------------------------------------------

.drawerContainer {
  .ant-drawer-header {
    border-bottom: 2px solid #b3b3b3;
  }
  .ant-drawer-body {
    padding: 0;
  }
}

// ------------------------------------------------------DataMeasurmentScreen----------------------------------------------------------

.ant-modal-body {
  padding: 0px !important;
  .ant-divider {
    border-top: 2px solid #dfdada;
    margin-bottom: 0px;
  }
  .ant-divider-vertical {
    height: 67px;
    border-left: 2px solid #dfdada;
  }
}

.dataScreenTab {
  .react-tabs__tab--selected {
    background: #fff !important;
    border-radius: 0px;
    border-bottom: 1px solid #e3e3e3 !important;
  }
  .react-tabs__tab {
    background: #d3d3d3;
    border-color: black;
    border-radius: 0px;
    border-bottom: 1px solid black;
  }
}

// -------------------------------------------- button styles --------------------------------------------------

.green500 {
  background-color: #17b7a5 !important;
}

.green700 {
  background-color: #17a697 !important;
}

#heiferHeightChartContainer {
  @include hideGridLines();
}

#matureWeightHeightChartContainer {
  @include hideGridLines();
}

#heiferWeightChartContainer {
  .recharts-cartesian-grid-horizontal {
    line:nth-child(even):not(:first-child):not(:last-child) {
      stroke: #e6e6e6 !important;
    }
  }
}

// ant drag upload component
.ant-upload.ant-upload-drag {
  height: 500px !important;
}

// styles for delete and cancel btns in app
.secondaryBtn {
  border-color: #000 !important;
  color: #808080 !important;
  background-color: #fff !important;
  &:hover {
    background-color: #e6e6e6 !important;
    color: #000 !important;
  }
}

.bulkHeiferTableContainer {
  .ant-table-cell {
    padding: 1px 8px !important;
    font-size: 12px;
  }
}

.tintColoredButton {
  text-align: center !important;
  background-color: #17a697 !important;
  color: white !important;
  border-radius: 5px !important;
  border: 1px solid #5784e5 !important;
}
