@import '../scss/variables';

@mixin inputField() {
  border-radius: 5px !important;
  border: 2px solid grey !important;
}

.formBorder {
  width: 480px;
  padding: 35px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #cccccc;
  box-shadow: 3px 3px 3px rgba(50, 50, 50, 0.28);
}

.signInHeading {
  font-size: 30px;
  font-weight: 400;
  padding-bottom: 30px;
  margin-bottom: 0px;
  margin-top: 15px;
  text-align: center;
}

.signInForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .signInInputField {
    font-size: 15px !important;
    padding: 7px !important;
    margin: 15px 0px !important;
    @include inputField();
  }
  .resetPasswordText {
    text-align: center;
    font-size: 18px;
    color: #5f5fe1;
  }

  .errorText {
    font-size: 19px;
    color: #ce0e2d;
  }
  .horizontalLine {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
    .textOnLine {
      background: #fff;
      padding: 0 10px;
      font-size: 20px;
    }
  }
}

.buttonColorDisabled {
  text-align: center !important;
  background: #dddddd !important;
  color: #666666 !important;
  border-color: #666666 !important;
  border-width: 1px;
}

.settingsInputField {
  @include inputField();
}

// style to match Input.Password eye suffix icon
.customSuffixClick {
  color: rgba(0, 0, 0, 0.45) !important;
  &:hover {
    color: #000 !important;
  }
}

/* update button style */
.updateBtn {
  text-align: center !important;
  background: $updateBtnColor !important;
  color: white !important;
  border-color: $updateBtnColor !important;
}
.updateBtn:hover {
  background: $updateBtnColor !important;
  color: white !important;
}
.updateBtn:active {
  background: $updateBtnColor !important;
  color: white !important;
  border-color: $updateBtnColor !important;
}
.updateBtn:focus {
  background: $updateBtnColor !important;
  color: white !important;
  border-color: $updateBtnColor !important;
}
.updateBtn:visited {
  background: $updateBtnColor !important;
  color: white !important;
  border-color: $updateBtnColor !important;
}
.updateBtn:focus-within {
  background: $updateBtnColor !important;
  color: white !important;
  border-color: $updateBtnColor !important;
}
