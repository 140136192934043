.headerContainer {
  height: 60px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: #17a697;
  padding-left: 40px;
  padding-right: 20px;

  .menuList {
    font-size: 18px;
    .link {
      color: #ffffff;
      text-decoration: none;
      margin: 0px 23px;
      padding-bottom: 0px;
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 3px;
    }
    .linkActive {
      @extend .link;
      font-weight: bold;
      border-bottom: 2px solid #ffffff;
    }
  }

  .logoutContainer {
    display: flex;
    flex-direction: column;
    padding: 5px 21px;
    color: #ffffff;
    cursor: pointer;
    justify-content: center !important;
    &:hover {
      color: #fff !important;
    }
  }
}

.linkMenu {
  font-weight: 600;
}

.ant-dropdown-menu-item > a {
  color: #17a697;
  font-weight: normal;
}
