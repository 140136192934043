.container {
  border: 2px solid #2b78e4;
  padding: 4px;
  font-size: 15px;
  border-radius: 5px;
  background-color: #e3f1ff;
  padding-right: 10px;

  .infoIcon {
    color: #2b78e4;
    margin: 0px 10px;
  }
}
