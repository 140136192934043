.inputContainer {
  display: flex;
  align-items: center;
  padding: 40px 20px 0;

  .labelName {
    width: 150px;
    text-align: end;
    font-size: 15px;
  }
  .inputField {
    width: 400px;
    margin-left: 30px;
    border: 1px solid #8b8b8b;
  }
  .reportTitleInput {
    @extend .inputField;
    margin-left: -30px !important;
  }
}
.buttonContainer {
  padding: 40px 200px;
}
.cancelButton {
  border: 1px solid #17a697 !important;
  margin-left: 20px;
}
.belowEmailText {
  font-style: italic;
  padding-left: 200px;
}
.errorText {
  padding-left: 200px;
  color: #ce0e2d;
}
.modalSaveButton {
  border: none !important;
  color: #40a9ff !important;
  width: 199px !important;
  font-size: 18px !important;
}
.modalCancelButton {
  @extend .modalSaveButton;
  color: black !important;
}
