.tabPanel {
  padding: 15px 35px;
}

.calculatedDataContainer {
  align-items: center;
  // needs to be overridden so as to align fields in one line
  width: calc(100% + 3rem);
}

.errorContainer {
  color: #ce0e2d;
  font-size: 12px !important;
}

.subHerdFormField {
  width: 150px !important;
  border: 1px solid;
}

.btnContainer {
  margin: 20px 0 !important;
}

.disabledNextBtn {
  background-color: #dddddd !important;
  color: #666 !important;
  border: 1px solid #333333 !important;
  width: 70px;
  margin-right: 60px;
}
