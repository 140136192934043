.deleteButton {
  border: 1px solid #17a697 !important;
  color: #17a697 !important;
  background-color: #eee !important;
}

.modalHeader {
  text-align: center;
  font-size: 23px;
  padding-top: 15px;
}

.formLabel {
  font-size: 16px;
  margin-bottom: 5px;
}

.formLabel:after {
  content: '*';
  color: red;
  margin-left: 2px;
}

.inputField {
  border: 1px solid;
}
