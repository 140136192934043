@mixin themeBg {
  background-color: #17a697 !important;
  border-color: #17a697;
}

.addHerdText {
  border: 2px solid #2b78e4;
  display: flex;
  align-items: center !important;
  padding: 4px;
  font-size: 15px;
  border-radius: 10px;
  width: 490px;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #e3f1ff;
  .addHerdIcon {
    color: #2b78e4;
    margin: 0px 20px;
  }
}
.cancelBtn {
  border: none !important;
  width: 249px;
  height: 40px !important;
  font-size: 17px !important;
  color: #000000;
  align-self: center !important;
}

.addButton {
  @extend .cancelBtn;
  color: #2b78e4 !important;
}

.editSaveBtn {
  @include themeBg();
  color: #fff !important;
  &:hover {
    background-color: #17b7a5 !important;
  }
}

.formContainer {
  .inputContainer {
    display: flex;
    align-items: center;
    margin: 20px 0 0;
    justify-content: space-evenly;

    .inputField {
      border-radius: 4px !important;
      border: 1px solid #8b8b8b;
    }

    .label {
      width: 90px;
      text-align: end !important;
    }
  }
}
.plusIcon {
  font-size: 12px;
}
.searchBox {
  padding: 8px;
}
.searchInput {
  width: 200px !important;
  margin-bottom: 8px;
  display: block !important;
}
.searchBtn {
  width: 90px;
  border-radius: 0 !important;
}
.resetBtn {
  width: 90px;
  border-radius: 0 !important;
}
hr {
  color: #333 !important;
}

.searchBtnContainer {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.addHeiferBtn {
  background-color: white !important;
  border-color: #17a697 !important;
  color: #17a697 !important;
  &:hover {
    background-color: #e4fff2 !important;
    color: #17a697 !important;
  }
  &:focus-visible {
    color: #000 !important;
  }
}
