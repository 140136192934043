.legendLabel {
  color: #585858;
  font-size: 12px;
}

svg.recharts-surface {
  width: 10px !important;
  height: 10px !important;
}

.chartWrapper {
  border: 1px solid #333;
  padding: 24px 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #fff;
}

.chartTitle {
  font-size: 24px;
  font-weight: 700;
  color: #585858;
  text-align: center;
  margin-bottom: 10px;
}

.legendContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.legendInnerContainer {
  display: flex;
  align-items: center;
}

.legendPenName {
  margin-right: 10px;
  font-weight: bold;
  font-size: 12px;
  color: #585858;
}
